* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}

.disable-btn {
  pointer-events: none;
  opacity: 0.3;
}
.active-link {
  border: 1px solid white;
  border-radius: 7px 0 7px 0;
}
.link {
  display: flex;
  gap: 25px;
  align-items: center;
  font-size: 13px;
}

.thin-y-scroll-bar::-webkit-scrollbar {
    width: 4px;
}

.thin-y-scroll-bar::-webkit-scrollbar-track {
    background: hsl(var(--muted));
    border-radius: 10px;
}

.thin-y-scroll-bar::-webkit-scrollbar-thumb {
    background: hsl(var(--destructive));
    border-radius: 10px;
}
