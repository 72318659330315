@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 50%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 15%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 95% 10%;
    --primary: 0 62.8% 30.6%;
    --primary-foreground: 0 0% 100%;
    --secondary: 0 10% 90%;
    --secondary-foreground: 0 0% 0%;
    --muted: -38 10% 95%;
    --muted-foreground: 0 0% 40%;
    --accent: -38 10% 90%;
    --accent-foreground: 0 0% 15%;
    --destructive: 0 50% 50%;
    --destructive-foreground: 0 0% 100%;
    --border: 0 0% 90%;
    --input: 0 0% 79%;
    --ring: 0 62.8% 30.6%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 10% 10%;
    --foreground: 0 0% 100%;
    --card: 0 0% 10%;
    --card-foreground: 0 0% 100%;
    --popover: 0 10% 5%;
    --popover-foreground: 0 0% 100%;
    --primary: 0 62.8% 30.6%;
    --primary-foreground: 0 0% 100%;
    --secondary: 0 10% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: -38 10% 25%;
    --muted-foreground: 0 0% 65%;
    --accent: -38 10% 25%;
    --accent-foreground: 0 0% 95%;
    --success: 120 100% 20%;
    --destructive: 0 50% 50%;
    --destructive-foreground: 0 0% 100%;
    --border: 0 20% 50%;
    --input: 0 20% 50%;
    --ring: 0 62.8% 30.6%;
    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
